<template>
  <div v-if="impressumData">
    <h1>{{ impressumData.Headline }}</h1>
    <h2>{{ impressumData.SubHeadline }}</h2>
    <p v-html="impressumData.Owner"></p>
    <p v-html="impressumData.Address"></p>
    <p>
      E-Mail: <a :href="'mailto:' + impressumData.Email" @click="emitLogData('impressum', 'mailtoLink')">{{ impressumData.Email }}</a><br>
      Internet: <router-link to="/">{{ impressumData.Homepage }}</router-link>
    </p>
    <p v-html="impressumData.Legal" />
    <h2>{{ impressumData.LiabilityHeadline }}:</h2>
    <p v-html="impressumData.LiabilityText" />
  </div>
</template>

<script>
export default {
  name: 'impressum',
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      impressumData: null
    };
  },
  methods: {
    emitLogData(page, action) {
      this.$emit(
        'log-it',
        '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
    const url = this.ajaxUrl + 'getData.php?';
    const params = {
      dataUrl: this.dataUrl,
      dataFile: 'impressum_de'
    }
    this.$http.post(url, params, {
      emulateJSON: true
    })
        .then(response => {
          this.impressumData = response.data;
        })
        .catch(() => {})
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('impressum', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('impressum', 'leave');
  }
}
</script>
