<template>
  <div v-if="contactData">
    <h1>{{ contactData.Headline }}</h1>
    <p
      class="q-mb-md"
      v-html="contactData.SubHeadline" />
    <!--
    <select
      class="form-element form-select q-mb-sm"
      :class="[Contact.Title === null ? 'form-error' : null, sendStateActive === true ? 'select-disabled' : null]"
      v-model="Contact.Title"
      :options="titleOptions"
      :label="contactData.Title">
        <option v-for="(title, index) in titleOptions" :key="index" :value="title.value">
          {{ title.text }}
        </option>
    </select> <span class="light">{{ contactData.Title }}</span><br>
    //-->
    <input
      type="text"
      v-model="Contact.Subject"
      :readonly="!subjectEmpty"
      :disabled="!subjectEmpty"
      class="form-element form-subject"
    > <span class="light">(Dein Thema)</span><br>
    <input
      class="form-element"
      :class="!Contact.FirstName ? 'form-error' : null"
      type="text"
      v-model="Contact.FirstName"
      :placeholder="contactData.FirstName" />
    <input
      class="form-element"
      :class="!Contact.LastName ? 'form-error' : null"
      type="text"
      v-model="Contact.LastName"
      :placeholder="contactData.LastName" />
    <input
      class="form-element"
      :class="!Contact.Email || !validateEmail(Contact.Email) ? 'form-error' : null"
      type="email"
      v-model="Contact.Email"
      :placeholder="contactData.Email" />
    <textarea
      outlined
      dense
      class="form-element form-textarea"
      :class="!Contact.Message ? 'form-error' : null"
      type="textarea"
      v-model="Contact.Message"
      :label="contactData.Message"
    />
    <div class="data-protection">
      <input type="checkbox"
        v-model="dataProtection"
        class="data-protection-check"
      />
      <div
        class="data-protection-hint"
        :class="!dataProtection ? 'hint-error' : null"
        v-html="contactData.DataProtection">
      </div>
    </div>
    <div class="clear-both">&nbsp;</div>
    <div v-if="messageSentSuccess !== null">
      <b-alert
        :variant="messageSentSuccess === true ? 'success' : 'danger'"
        show
        dismissible
        class="notification"
        v-html="sentMessage">
      </b-alert>
    </div>
    <b-button
      :label="contactData.FormButton"
      :disabled="!formValidation() || !dataProtection || sendStateActive === true || messageSentSuccess === true"
      class="submit-button"
      @click="sendMessage()"
    >
      <i class="fas fa-at" />{{ contactData.FormButton }}
    </b-button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'kontakt',
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      contactData: null,
      Contact: {
        Subject: '',
        FirstName: '',
        LastName: '',
        Email: '',
        Message: ''
      },
      messageSentSuccess: null,
      sentMessage: null,
      subjectEmpty: true,
      /*
      titleOptions: [{
        value: null,
        text: '- -'
      }, {
        value: 'Frau',
        text: 'Frau'
      }, {
        value: 'Herr',
        text: 'Herr'
      }, {
        value: 'Frau Dr.',
        text: 'Frau Dr.'
      }, {
        value: 'Herr Dr.',
        text: 'Herr Dr.'
      }, {
        value: 'Frau Dr. Dr.',
        text: 'Frau Dr. Dr.'
      }, {
        value: 'Herr Dr. Dr.',
        text: 'Herr Dr. Dr.'
      }, {
        value: 'Frau Prof.',
        text: 'Frau Prof.'
      }, {
        value: 'Herr Prof.',
        text: 'Herr Prof.'
      }, {
        value: 'Frau Prof. Dr.',
        text: 'Frau Prof. Dr.'
      }, {
        value: 'Herr Prof. Dr.',
        text: 'Herr Prof. Dr.'
      }],
       */
      dataProtection: false,
      sendStateActive: false
    };
  },
  methods: {
    /**
     * method for getting saved contact data from localStorage
     */
    getContactStore() {
      if (localStorage.getItem('contact') !== null) {
        let contact = JSON.parse(localStorage.getItem('contact'));
        contact.Message = '';
        this.Contact = contact;
      }
    },

    /**
     * method for updating contact data within loaclStorage
     */
    updateContactStore() {
      localStorage.setItem('contact', JSON.stringify(this.Contact));
    },

    /**
     * method for validating email
     * @param email
     * @returns {boolean}
     */
    validateEmail(email) {
      let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return re.test(String(email).toLowerCase());
    },

    /**
     * method for validating contact form
     * @returns {boolean}
     */
    formValidation() {
      let formValid = true;

      // if (!this.Contact.Title) { formValid = false; }
      if (!this.Contact.FirstName) { formValid = false; }
      if (!this.Contact.LastName) { formValid = false; }
      if (!this.Contact.Email || !this.validateEmail(this.Contact.Email)) { formValid = false; }
      if (!this.Contact.Message) { formValid = false; }
      if (!this.dataProtection) { formValid = false; }

      return formValid;
    },

    /**
     * method for sending contact message
     */
    sendMessage() {
      if (this.formValidation() === true) {
        this.sendStateActive = true;
        this.updateContactStore();
        this.emitLogData('kontakt', 'sendMessageButton');

        let url = this.ajaxUrl + 'contact.php';
        /*
        const params = {
          contactData: this.Contact
        }
         */

        axios.post(url, 'contactData=' + encodeURIComponent(JSON.stringify(this.Contact)))
        // this.$http.post(url, params)
          .then((response) => {
            if (response.data === 'ERROR') {
              this.sentMessage = this.contactData.AjaxErrorMessage;
              this.messageSentSuccess = false;
            } else {
              this.sentMessage = this.contactData.AjaxSuccessMessage;
              this.messageSentSuccess = true;
              this.Contact.Message = ' ';
            }
            this.sendStateActive = false;
          })
          .catch(() => {
            this.sentMessage = this.contactData.AjaxErrorMessage;
            this.messageSentSuccess = false;
            this.sendStateActive = false;
          })
      } else {
        this.sentMessage = this.contactData.AjaxErrorMessage;
        this.messageSentSuccess = false;
        this.sendStateActive = false;
      }
    },
    emitLogData(page, action) {
      this.$emit(
        'log-it',
        '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
    this.getContactStore();

    const url = this.ajaxUrl + 'getData.php?';
    const params = {
      dataUrl: this.dataUrl,
      dataFile: 'kontakt_de'
    }
    this.$http.post(url, params, {
      emulateJSON: true
    })
        .then(response => {
          this.contactData = response.data;
        })
        .catch(() => {})

    this.Contact.Subject = this.$route.query.referer ? this.$route.query.referer : '';
    this.subjectEmpty = this.Contact.Subject === '';
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('kontakt', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('kontakt', 'leave');
  }
};
</script>

<style lang="scss" scoped>
  .contact-container {
    max-width: 800px;
  }
  .light {
    color: grey;
    padding-left: 10px;
  }
  .form-element {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    margin: 5px 0;
    border: 1px solid #bbb;
    border-radius: 0.25rem;
  }
  .form-subject {
    width: 70%;
  }
  .form-select {
    width: 50%;
    padding: 0 7px;
  }
  .form-textarea {
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .form-error,
  .q-field--error {
    border-color: #CC0000 !important;
  }
  .hint-error {
    color: #CC0000;
  }
  .data-protection {
    margin: -10px 0 15px -10px;
  }
  .data-protection-check {
    float: left;
    height: 20px;
    width: 20px;
    margin: 11px 10px 10px 10px;
  }
  .data-protection-hint {
    float: left;
    max-width: 90%;
    text-align: left;
    margin-top: 10px;
  }
  .clear-both {
    clear: both;
    font-size: 0;
    margin: 0;
    padding: 0;
  }
  .submit-button {
    color: #FFFFFF !important;
    margin: 12px 0 0 0;
    cursor: pointer;
  }
  .submit-button:hover {
    text-decoration: none !important;
  }
  .fa-at {
    margin-left: -5px;
  }
  .notification {
    text-align: left;
    margin: 10px 0;
  }
  .alert {
    padding: 6px 9px;
  }
</style>
