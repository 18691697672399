<template>
  <div v-if="jsonData">
    <h1>{{ jsonData.Headline }}</h1>
    <img
        v-lazy="dataUrl + jsonData.BackgroundImage"
        :alt="jsonData.BackgroundImage"
        width="100%"
        class="product-image"
    />
    <div class="description-container">
      <p v-html="jsonData.ShortDescription" />
      <p v-html="jsonData.LongDescription" />
    </div>
    <h2 class="benefits-headline">{{ jsonData.BenefitsHeadline }}</h2>
    <div class="benefits-container">
      <ul class="benefits-list">
        <li
            v-for="(item, index) in jsonData.Benefits"
            :key="index"
            class="benefits-item"
            v-html="item.Title"
        />
      </ul>
    </div>
    <div class="prices-container">
      <ul class="benefits-list">
        <li
            v-for="(item, index) in jsonData.Prices"
            class="prices-item"
            :key="index"
        >
        <span
            class="prices-description"
            v-html="item.Description"
        />
          <span
              class="prices-price"
              v-html="item.Value" />
        </li>
      </ul>
    </div>
    <p
        class="price-hint"
        v-html="jsonData.PriceHint"
    />
    <div class="description-container">
      <p>{{ jsonData.ContactDescription }}</p>
      <router-link :to="jsonData.ContactUrl">Nimm Kontakt mit mir auf...</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'basische-ernaehrung',
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      jsonData: null,
      productToggle: []
    };
  },
  methods: {
    emitLogData(page, action) {
      this.$emit(
          'log-it',
          '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
    const url = this.ajaxUrl + 'getData.php?';
    const fullPath = this.$route.fullPath;
    const file = fullPath.replace(/\//g, '-').substring(1, fullPath.length);
    console.log(file);
    const params = {
      dataUrl: this.dataUrl,
      dataFile: file + '_de'
    }
    this.$http.post(url, params, {
      emulateJSON: true
    })
        .then(response => {
          this.jsonData = response.data;
        })
        .catch(() => {});

  },
  mounted() {
    this.emitLogData('ernaehrung-und-gesundheit/basische-ernaehrung', 'visit');
  },
  beforeDestroy() {
    this.emitLogData('ernaehrung-und-gesundheit/basische-ernaehrung', 'leave');
  }
}
</script>
