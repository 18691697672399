<template>
  <div>
    <div style="font-size: 20vh">
      404
    </div>
    <div class="text-h2" style="opacity:.4; margin-bottom: 20px;">
      Uups. Hier ist nichts...
    </div>
    <router-link to="/" tag="button">Zurück zur Homepage</router-link>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  methods: {
    emitLogData(page, action) {
      this.$emit(
        'log-it',
        '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('404', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('404', 'leave');
  }
}
</script>
