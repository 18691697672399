<template>
  <!--
  <div v-if="downloadData">
    <h1>{{ downloadData.Headline }}</h1>
    <p
        class="download-description"
        v-html="downloadData.Description"
    />
    <div
      v-for="(item, index) in downloadData.Files"
      :key="index">
      <h3>{{ item.Name }}</h3>
      <a :href="item.Link">
        {{ item.Link }}
        <i class="fas fa-download"></i>
      </a>
    </div>
  </div>
  //-->
</template>

<script>
export default {
  name: 'download',
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      downloadData: null
    };
  },
  methods: {
    emitLogData(page, action) {
      this.$emit(
          'log-it',
          '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
    const url = this.ajaxUrl + 'getData.php?';
    const params = {
      dataUrl: this.dataUrl,
      dataFile: 'downloads_de'
    }
    this.$http.post(url, params, {
      emulateJSON: true
    })
        .then(response => {
          this.downloadData = response.data;
        })
        .catch(() => {})
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('downloads', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('downloads', 'leave');
  }
}
</script>

<style scoped lang="scss">
.download-description {
  margin-bottom: 40px;
}
</style>
