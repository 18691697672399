<template>
  <div v-if="liabilityData">
    <h1>{{ liabilityData.Headline }}</h1>
    <div v-html="liabilityData.Text" />
  </div>
</template>

<script>
export default {
  name: 'datenschutz',
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      liabilityData: null
    };
  },
  methods: {
    emitLogData(page, action) {
      this.$emit(
        'log-it',
        '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
    const url = this.ajaxUrl + 'getData.php?';
    const params = {
      dataUrl: this.dataUrl,
      dataFile: 'datenschutz_de'
    }
    this.$http.post(url, params, {
      emulateJSON: true
    })
        .then(response => {
          this.liabilityData = response.data;
        })
        .catch(() => {})
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('datenschutz', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('datenschutz', 'leave');
  }
}
</script>
