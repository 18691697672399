<template>
  <product-content
      :ajaxUrl="ajaxUrl"
      :dataUrl="dataUrl"
  />
</template>

<script>
import ProductContent from '@/components/ProductContent';

export default {
  components: {
    ProductContent
  },
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    emitLogData(page, action) {
      this.$emit(
          'log-it',
          '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('ernaehrung-bei-uebergewicht', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('ernaehrung-bei-uebergewicht', 'leave');
  }
}
</script>
