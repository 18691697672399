<template>
  <div
    v-if="meData"
    class="me-container"
  >
    <h1 :style="'font-size: ' + meData.HeadlineFontSize + 'em !important;'">{{ meData.Headline }}</h1>
    <div
      v-html="meData.ShortDescription"
      class="short-description"
      :style="'font-size: ' + meData.ShortDescriptionFontSize + 'em;'"
    />
    <img
      v-lazy="dataUrl + meData.Image"
      :alt="meData.Image"
      style="width: 100%; max-width: 500px; border: 1px solid lightgrey;"
    />
    <div
      class="me-self"
      v-html="meData.LongDescription"
      :style="'font-size: ' + meData.LongDescriptionFontSize + 'em;'"
    />
    <h1 :style="'font-size: ' + meData.SkillsHeadlineFontSize + 'em !important;'">{{ meData.SkillsHeadline }}</h1>
    <div
        class="me-skills"
        v-html="meData.SkillsDescription"
        :style="'font-size: ' + meData.SkillsDescriptionFontSize + 'em;'"
    />
    <img
        v-lazy="dataUrl + meData.SkillsImage"
        :alt="meData.SkillsImage"
        style="width: 100%; max-width: 350px; border: 1px solid lightgrey;"
    />
  </div>
</template>

<script>
export default {
  name: 'ueber-mich',
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      meData: null
    };
  },
  methods: {
    emitLogData(page, action) {
      this.$emit(
        'log-it',
        '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
    const url = this.ajaxUrl + 'getData.php?';
    const params = {
      dataUrl: this.dataUrl,
      dataFile: 'ueber-mich_de'
    }
    this.$http.post(url, params, {
      emulateJSON: true
    })
        .then(response => {
          this.meData = response.data;
        })
        .catch(() => {})
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('ueber-mich', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('ueber-mich', 'leave');
  }
}
</script>

<style>
.me-container {
  max-width: 800px;
  text-align: center;
}
.short-description {
  margin-bottom: 15px;
}
.me-self {
  margin: 20px 0 40px 0;
}
.me-skills {
  margin-bottom: 20px;
}
</style>
