<template>
  <div v-if="aktuellesData">
    <div
      v-for="item in aktuellesData"
      class="aktuelles"
    >
      <div class="date">{{ item.Date }}</div>
      <h1>{{ item.Headline }}</h1>
      <img
          v-if="item.Image !== ''"
          v-lazy="dataUrl + item.Image"
          :alt="item.Image"
          width="100%"
          class="product-image"
      />
      <div v-html="item.Text" />
    </div>
  </div>
  <div v-else>
    Bald sind hier aktuelle News zu sehen.
  </div>
</template>

<script>
export default {
  name: 'cookies',
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      aktuellesData: null
    };
  },
  methods: {
    emitLogData(page, action) {
      this.$emit(
          'log-it',
          '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
    const url = this.ajaxUrl + 'getData.php?';
    const params = {
      dataUrl: this.dataUrl,
      dataFile: 'aktuelles_de'
    }
    this.$http.post(url, params, {
      emulateJSON: true
    })
        .then(response => {
          this.aktuellesData = response.data;
        })
        .catch(() => {})
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('aktuelles', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('aktuelles', 'leave');
  }
}
</script>

<style scoped lang="scss">
.aktuelles {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid lightgray;
}
.date {
  color: gray;
  font-size: .9em;
}
</style>
