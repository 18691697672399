<template>
  <div v-if="trainingData">
    <h1>{{ trainingData.Headline }}</h1>
    <div
      class="description-container"
      v-html="trainingData.LongDescription"
    />
    <div class="products-container">
      <div
          v-for="(product, index) in trainingData.Products"
          :key="index"
          @click="goToProduct(product.Url)"
          class="product-item">
        <p class="product-headline">{{ product.Name }}</p>
        <img
            v-lazy="dataUrl + product.Image"
            :alt="product.BackgroundImage"
            width="100%"
        />
        <p
            class="product-description"
            :title="'Gehe zu \'' + product.Name + '\''"
            v-html="product.ShortDescription + '<i class=\'fas fa-link\'></i>'"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'personal-training',
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      trainingData: null,
      productToggle: []
    };
  },
  methods: {
    goToProduct(url) {
      this.$router.push({ path: url});
    },

    emitLogData(page, action) {
      this.$emit(
          'log-it',
          '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
    const url = this.ajaxUrl + 'getData.php?';
    const params = {
      dataUrl: this.dataUrl,
      dataFile: 'personal-training_de'
    }
    this.$http.post(url, params, {
      emulateJSON: true
    })
        .then(response => {
          this.trainingData = response.data;
        })
        .catch(() => {})
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('personal-training', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('personal-training', 'leave');
  }
}
</script>

<style>
.description-container {
  padding: 5px 0 15px 0;
}
.products-container {
  padding: 5px 0;
}
.product-headline {
  font-size: 1.2em;
  font-weight: bold;
  padding: 15px 10px 0 10px;
}
.product-item {
  border: 1px solid lightgrey;
  margin-bottom: 20px;
  cursor: pointer;
}
.product-item:hover {
  box-shadow: 0 0 6px #AAAAAA;
}
.product-description {
  padding: 15px 10px 0 10px;
}
</style>
