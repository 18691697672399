<template>
  <div v-if="blogData">
    <h1>{{ blogData.Headline }}</h1>
    <img
      v-lazy="dataUrl + blogData.BackgroundImage"
      :alt="blogData.BackgroundImage"
      width="100%"
      style="border: 1px solid lightgrey;"
    />
    <p
      class="blog-description"
      v-html="blogData.Description"
    />
    <div
      v-for="(item, index) in blogDataList"
      :key="index"
      :style="getStyle(index)"
      class="blog-item"
    >
      <div class="blog-date">{{ item.date }}</div>
      <h3>{{ item.title }}</h3>
      <div v-if="!item.showLongText">
        <span v-html="item.shortText" />
        <div
          class="more-less-btn"
          @click="item.showLongText = true">
          <b-button class="btn">
            <i class="fas fa-caret-down" />
            <span class="more-less-text">Mehr anzeigen</span>
          </b-button>
        </div>
      </div>
      <div v-else>
        <span v-html="item.longText" />
        <div
          v-if="item.quellen"
          class="quellen"
        >
          <div class="quelle-headline">Quellen</div>
          <div v-for="quelle in item.quellen">
            <a :href="quelle.Url" target="_blank">{{ quelle.Text }}</a>
          </div>
        </div>
        <div
          class="more-less-btn"
          @click="item.showLongText = false">
          <b-button class="btn">
            <i class="fas fa-caret-up" />
            <span class="more-less-text">Weniger anzeigen</span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blog',
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      blogData: null,
      blogDataList: [],
    };
  },
  methods: {
    getStyle(index) {
      let style = 'border-top: 1px solid lightgrey; ';
      style += (index % 2 === 0 ? 'float: left;' : 'float: right;') + ' clear: both;';
      return style;
    },

    emitLogData(page, action) {
      this.$emit(
          'log-it',
          '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
    const url = this.ajaxUrl + 'getData.php?';
    const params = {
      dataUrl: this.dataUrl,
      dataFile: 'blog_de'
    }
    this.$http.post(url, params, {
      emulateJSON: true
    })
      .then(response => {
        this.blogData = response.data;
        this.blogData.Data.forEach(item => {
          this.blogDataList.push({
            date: item.Date,
            title: item.Title,
            data: item,
            image: item.Image,
            shortText: item.Text.substring(0, 201) + '...',
            longText: item.Text,
            quellen: item.Quellen,
            showLongText: false
          });
        });
      })
      .catch(() => {})
  },
  mounted() {
    setTimeout(() => {
      this.emitLogData('blog', 'visit');
    }, 10);
  },
  beforeDestroy() {
    this.emitLogData('blog', 'leave');
  }
}
</script>

<style scoped lang="scss">
.theme-img {
  border: 1px solid lightgrey;
}
.blog-description {
  margin-top: 15px;
}
.blog-item {
  width: 90%;
  padding: 10px 0 6px 0;
}
.blog-date {
  color: gray;
  font-size: .9em;
}
.more-less-btn {
  margin: 0 0 10px 20px;
  cursor: pointer;
}
.btn {
  background: #696969 !important;
}
.btn:active,
.btn:hover {
  color: #FFFFFF !important;
  text-decoration: none !important;
}
.more-less-btn:hover {
  color: #FFFFFF !important;
  text-decoration: none !important;
}
.more-less-text,
.more-less-text:hover {
  color: #FFFFFF !important;
  text-decoration: none !important;
}
.fas {
  color: #FFFFFF !important;
  margin-left: -6px;
}
.quellen {
  margin: 30px 0;
}
.quelle-headline {
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
